import actions from "../actions";
import { PaymentMethods } from "lib/helpers/constants";

const initialState = {
  payment: {},
  selectedPaymentMethod: PaymentMethods.HIPAY_MBWAY,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.payment.hipayMbwayPaymentRequest.action}_FULFILLED`: {
      return {
        ...state,
        payment: action.payload.data,
      };
    }
    case `${actions.payment.getHipayMbwayPaymentStatus.action}_FULFILLED`: {
      return {
        ...state,
        payment: action.payload.data,
      };
    }
    case actions.payment.selectPaymentMethod.action: {
      return {
        ...state,
        selectedPaymentMethod: action.payload,
      }
    }
    default:
      return state;
  }
}
