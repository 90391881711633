import actions from "../actions";

const initialState = {
  // Shop info:
  // addressView,
  // ...
  // website,
  calendarSlots: {
    // [shopMerchantServiceId]: [
    // {
    //   startTime: "2021-04-29T09:29:33.271Z",
    //   durationSec: 1800,
    //   capacity: 9999,
    // },
    // ]
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.shop.fetchShop.action}_FULFILLED`: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case `${actions.shop.fetchShopCalendarSlots.action}_FULFILLED`: {
      return {
        ...state,
        calendarSlots: {
          ...state.calendarSlots,
          [action.meta.shopMerchantServiceId]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
}
