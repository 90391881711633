import actions from "../actions";

const initialState = {
  cart: {
    id: null,
    items: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.shoppingCart.fetchShoppingCart.action}_FULFILLED`: {
      return {
        ...state,
        cart: { ...action.payload.data },
      };
    }
    case actions.shoppingCart.addItemToCart.action: {
      const item = action.payload;

      return {
        ...state,
        cart: {
          ...state.cart,
          items: [...state.cart.items, item],
        }
      }
    }
    case actions.shoppingCart.removeItemFromCart.action: {
      const itemId = action.payload;

      return {
        ...state,
        cart: {
          ...state.cart,
          shops: state.cart.shops.map((shop) => ({
            ...shop,
            items: shop.items.filter((i) => i.id !== itemId),
          })),
        }
      }
    }
    default:
      return state;
  }
}
