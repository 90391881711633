import actions from "../actions";

const initialState = {
  // Base user info from the login() request
  id: null,
  username: null,
  firstName: null,
  lastName: null,
  genderId: null,
  points: null,
  roleId: null,
  stateId: null,
  lastLoggedOn: null,
  avatar: null,
  facebookId: null,
  nrFollowers: null,
  nrReviews: null,
  isFollowed: null,
  isPremium: null,
  country: null,
  countryId: null,
  district: null,
  districtId: null,
  countyId: null,
  zoneId: null,
  balance: null,
  hasDistrict: null,
  location: null,
  locationUrl: null,
  mobile: null,
  referralCode: null,
  newRegistration: null,
  token: null,
  accessToken: null,
  channelId: null,
  sessionStart: null,
  floatingWindowTimeout: null,
  timeout: null,
  additionalChannelInfo: null,
  languageCode: null,
  realm: null,
  permissions: null,
  // The authentication provider used by the user ("facebook" or "mygon")
  loginSource: null,
  // Users' prizes from the fetchPrizes() request
  prizes: [
    // Example data:
    // id: 9501
    // prizeId: 1
    // prizeTitle: "Vale de 5 €"
    // prizeDescription: "Vale de 5€ de desconto adicional em qualquer estabelecimento Mygon com campanhas ativas. Após obter o prémio serás contactado no prazo de 72 horas para a validação do mesmo."
    // date: "2020-01-16T10:38:30.000+0000"
    // expirationDate: "2021-01-17T00:00:00.000+0000"
    // userNotes: ""
    // obtainedStatus: "Disponível para usar"
    // usedStatusId: 1
    // usedStatus: "Disponível para usar"
    // campaignView: null
  ]
  // id: 272457, //  null,
  // token: "5361e38f-50af-4719-894d-513ed7c8fcc7" // null
};

export default function reducer(state = initialState, action = {}) {
  const handleLogin = loginSource => ({
    ...state,
    ...action.payload.data,
    loginSource
  });

  switch (action.type) {
    case actions.user.setUserInfo.action: {
      return {
        ...state,
        ...action.payload
      };
    }
    case `${actions.user.fetchUserWithExternalAuth.action}_FULFILLED`:
    case `${actions.user.fetchUser.action}_FULFILLED`: {
      return {
        ...state,
        ...action.payload.data,
        token: action.payload.data.token || action.meta.token,
        username: action.payload.data.email
      };
    }
    case `${actions.user.login.action}_FULFILLED`:
      return handleLogin("mygon");
    case `${actions.user.loginWithFacebook.action}_FULFILLED`:
      return handleLogin("facebook");
    case `${actions.user.loginWithGoogle.action}_FULFILLED`:
      return handleLogin("google");
    case `${actions.user.loginWithApple.action}_FULFILLED`:
      return handleLogin("apple");
    case `${actions.user.fetchPrizes.action}_FULFILLED`: {
      return {
        ...state,
        prizes: action.payload.data.prizes || []
      };
    }
    default:
      return state;
  }
}
