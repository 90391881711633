import logger from "redux-logger";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";

export default function setup({ debug }) {
  return [
    thunk,
    promise,
    // Logger must be last. Don't move it!
    // https://github.com/LogRocket/redux-logger/issues/20
    debug && logger,
  ].filter(Boolean);
}
