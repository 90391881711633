import user from "./user"
import campaign from "./campaign"
import shop from "./shop"
import flow from "./flow"
import context from "./context"
import product from "./product"
import categories from "./categories"
import payment from "./payment"
import shoppingCart from "./shopping-cart"
import entity from "./entity"
import deliveries from "./deliveries"
import addresses from "./addresses"

export default {
  user,
  campaign,
  shop,
  flow,
  context,
  product,
  categories,
  payment,
  shoppingCart,
  entity,
  deliveries,
  addresses,
}
