import actions from "../actions";

const initialState = {
  // Campaign Info
  info: {
    // campaignView,
    // priceListView,
    // ...
  },
  // Campaign (shop's) working hours time table and days it's closed
  schedule: {
    // "startDate": "2020-01-14T10:45:32.343+0000",
    // "endDate": "2020-03-14T10:45:32.343+0000",
    // "nonAvailableDays": [
    //   "2020-01-14T10:45:32.343+0000"
    // ]
  },
  calendarSlots: {},
};

// The middleware sends attributes as an array of objects.
// [ {type: "SMART_DISCOUNT", value: "47"}, ... ]
// Makes no sense... Transform them to an object.
const campaignAttributesToObject = (attributes) => {
  return (attributes || []).reduce((accum, entry) => {
    return {
      ...accum,
      [entry.type]: entry.value,
    };
  }, {});
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.campaign.fetchCampaign.action}_FULFILLED`: {
      return {
        ...state,
        info: {
          ...action.payload.data,
          campaignView: {
            ...action.payload.data.campaignView,
            attributes: campaignAttributesToObject(
              action.payload.data.campaignView.attributes
            ),
          },
        },
      };
    }
    case `${actions.campaign.fetchSchedule.action}_FULFILLED`: {
      return {
        ...state,
        schedule: { ...action.payload.data },
      };
    }
    case `${actions.campaign.fetchCalendarSlots.action}_FULFILLED`: {
      return {
        ...state,
        calendarSlots: {
          ...state.calendarSlots,
          [action.meta.campaignId]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
}
