import actions from "../actions";

const initialState = {
  userId: null,
  token: null,
  externalToken: null, // The `token` passed in by MyEdenred (providerId/entityId 4)
  entityId: 1,
  providerId: 1, // Arrives as `entityId` in MyEdenred(as it should) - deprecated for entityId
  clientApplication: "WEB", // used for requests
  clientPlatform: "WIDGET", // WEB, Android, ios - use for booking
  language: "pt_PT",
  showClose: false,
  canBookDate: true,
  canBuyVoucher: true,
  channelManager: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.context.setContext.action: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
