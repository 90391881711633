/* eslint-disable no-underscore-dangle */
import { applyMiddleware, createStore, compose } from "redux";
import {
  // createMigrate,
  persistCombineReducers,
  persistStore
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import getMiddleware from "./middleware";
import reducers from "./reducers";
import selectors from "./selectors";
import actions from "./actions";
// import migrations from "./storage/migrations";

let composeEnhancers = compose;
if (typeof window !== "undefined") {
  // Weird code for validating `window` because of Next.js build error
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const persistConfig = {
  key: "mygon-reservation-widget",
  version: 1,
  // migrate: createMigrate(migrations, { debug: false }),
  storage
};

const createApplicationStore = ({
  initialState = null,
  persist = false,
  onRehydrated = null,
  debug = false
} = {}) => {
  const combinedReducers = persistCombineReducers(persistConfig, reducers);
  const middleware = getMiddleware({ debug });

  const store = createStore(
    ...[
      combinedReducers,
      initialState,
      composeEnhancers(applyMiddleware(...middleware))
    ].filter(Boolean)
  );

  const persistor = persist ? persistStore(store, null, onRehydrated) : null;

  return { persistor, store };
};

export { createApplicationStore, actions, reducers, selectors };
