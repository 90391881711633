import actions from "../actions";

const initialState = {
  // Campaign or Service reservation info
  reservation: {
    // campaignAddress: "R. Portão de São Tiago, S/N, Funchal, Madeira, Portugal"
    // campaignId: null
    // campaignSummaryTitle: null
    // campaignTitle: null
    // campaignType: 1
    // categoryName: null
    // clientBookPoints: null
    // currency: "€"
    // dateScheduled: "18 Jun"
    // expiryTime: "10:30"
    // merchantId: 249807
    // mobilePhone: "+351934298058"
    // numberPeoples: 1
    // phoneNumber: "938335056"
    // pricePerPerson: 0
    // promotionCode: "ohcib"
    // shopName: "Restaurante Teste"
    // subCategoryName: null
    // timeScheduled: "10h00"
    // transactionId: 593128
    // userTotalPoints: 1615
    // valueCharged: 0
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.product.book.action}_FULFILLED`: {
      return {
        ...state,
        reservation: { ...action.payload.data },
      };
    }
    default:
      return state;
  }
}
