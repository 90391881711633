import React from "react";
// Importing the whole of core-js because we couldn't get it to build for
// production with custom babel or core-js configurations. To be imporoved.
import "core-js";
import App from "next/app";
import { Provider } from "react-redux";
import { createApplicationStore } from "lib/redux";
import "lib/helpers/i18n";
import "styles/bootstrap-mygon-widget-theme.scss";

const debug = process.env.NODE_ENV === "development";

class MyApp extends App {
  constructor(props) {
    super(props);

    this.state = {
      persistor: null,
      store: null
    };
  }
  componentDidMount() {
    // Only called on the client
    const { persistor, store } = createApplicationStore({
      debug,
      persist: false
    });
    this.setState({ persistor, store });
  }

  render() {
    const { store } = this.state;
    const { Component, pageProps } = this.props;
    if (store) {
      return (
        <Provider store={store}>
          <Component {...pageProps} />
        </Provider>
      );
    } else {
      return <div></div>;
    }
  }
}

export default MyApp;
