import actions from "../actions"

const initialState = {
  deliveryMethods: [],
  deliveryRates: [],
  deliveryCountries: [],
  pickupPoints: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.deliveries.fetchDeliveryMethods.action}_FULFILLED`: {
      return {
        ...state,
        deliveryMethods: action.payload.data,
      }
    }
    case `${actions.deliveries.fetchDeliveryCountries.action}_FULFILLED`: {
      return {
        ...state,
        deliveryCountries: action.payload.data,
      }
    }
    case `${actions.deliveries.fetchDeliveryRates.action}_FULFILLED`: {
      return {
        ...state,
        deliveryRates: action.payload.data,
      }
    }
    case `${actions.deliveries.fetchPickupPoints.action}_FULFILLED`: {
      return {
        ...state,
        pickupPoints: action.payload.data,
      }
    }
    default:
      return state
  }
}
