import actions from "../actions";

const initialState = {
  categories: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${actions.categories.fetchCategories.action}_FULFILLED`: {
      return {
        ...state,
        categories: action.payload.data,
      };
    }
    default:
      return state;
  }
}
