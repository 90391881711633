import actions from "../actions"

const initialState = {
  addresses: [],
  address: {
    id: null,
    addressName: '',
    countryId: 139,
    city: '',
    postalCode: '',
    street: '',
    floorDoor: '',
    company: '',
    vatId: '',
  },
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.addresses.setAddressState.action: {
      const {data} = action.payload;
      return {
        ...state,
        address: {
          ...state.address,
          ...data,
        },
      }
    }
    case `${actions.addresses.fetchAddresses.action}_FULFILLED`: {
      return {
        ...state,
        addresses: action.payload.data,
      }
    }
    case `${actions.addresses.createUpdateAddress.action}_FULFILLED`: {
      const addresses = state.addresses
      const address = action.payload.data
      const index = addresses.findIndex(a => a.id === address.id)
      if (index !== -1) {
        addresses[index] = address
      } else {
        addresses.push(address)
      }
      return {
        ...state,
        addresses,
      }
    }
    case `${actions.addresses.deleteAddress.action}_FULFILLED`: {
      return {
        ...state,
        deliveryRates: action.payload.data,
      }
    }
    default:
      return state
  }
}
